@font-face {
	font-family: Arsenal;
	src: url("/Media/static/fonts/Arsenal-Regular.woff2") format("woff2"),
		url("/Media/static/fonts/Arsenal-Regular.woff") format("woff");
	font-weight: normal;
	font-style: normal;
	// font-display: swap;
}

// @font-face {
// 	font-family: Arsenal;
// 	src: url('/Media/static/fonts/Arsenal-Italic.woff2') format('woff2'),
// 	url('/Media/static/fonts/Arsenal-Italic.woff') format('woff');
// 	font-weight: normal;
// 	font-style: italic;
// }

@font-face {
	font-family: Arsenal;
	src: url("/Media/static/fonts/Arsenal-Bold.woff2") format("woff2"),
		url("/Media/static/fonts/Arsenal-Bold.woff") format("woff");
	font-weight: bold;
	font-style: normal;
	// font-display: swap;
}

// @font-face {
// 	font-family: Arsenal;
// 	src: url('/Media/static/fonts/Arsenal-BoldItalic.woff2') format('woff2'),
// 	url('/Media/static/fonts/Arsenal-BoldItalic.woff') format('woff');
// 	font-weight: bold;
// 	font-style: italic;
// }
